import { faker } from '@faker-js/faker'
import { Deal, DealStatus, DealLabel } from 'types'

const range = (len: number) => {
  const arr = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

const newDeal = (): Deal => ({
  id: faker.datatype.uuid(),
  user: faker.internet.userName(),
  toCurrency: {
    id: 'a6b0eb33-175f-4831-8c4a-041ea22b7fda',
    slug: 'CAD',
  },
  fromCurrency: {
    id: 'fbb12878-b5ef-49f3-a6a4-9923fd3584d8',
    slug: 'NGN',
  },
  progress: faker.datatype.number(100),
  amount: faker.datatype.number(10000),
  totalSold: faker.datatype.number(10000),
  rate: faker.datatype.number({ min: 290, max: 310 }),
  commission: 0,
  label: faker.helpers.arrayElement<Deal['label']>(Object.values(DealLabel)),
  status: faker.helpers.arrayElement<Deal['status']>(Object.values(DealStatus)),
})

export function makeDealsData(qTy = 20) {
  return range(qTy).map(() => {
    return newDeal()
  })
}
