import {
  Box,
  Button,
  chakra,
  Grid,
  GridItem,
  Stack,
  Tab,
  TabList,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'
import { userAccountIsSetup } from 'store/selectors/user'

const availableCurrencies = [
  { title: 'CAD deals', link: 'CAD' },
  { title: 'NGN deals', link: 'NGN' },
]

export const HomePage: FC = () => {
  const track = useTrackAction()
  const { t } = useTranslation()
  const params = useParams<{
    currency: string
  }>()
  const navigate = useNavigate()
  useEffect(() => {
    track?.(AmplitudeEvents.GO_TO_MARKETPLACE)
    if (!params.currency || !availableCurrencies.find((item) => item.link === params.currency)) {
      // navigate(availableCurrencies[1].link)
      navigate('NGN')
    }
  }, [params, track])
  const tabIndex = useMemo(() => {
    return availableCurrencies.findIndex((item) => item.link === params.currency)
  }, [params])
  const size = useBreakpointValue({ base: 'mobile', lg: 'desktop' }, { ssr: false })
  const isAccountSetup = useSelector(userAccountIsSetup)

  return (
    <Grid
      templateColumns={'1fr'}
      templateRows='max-content auto'
      maxH={isAccountSetup ? 'full' : `calc(100% - 6.28rem)`}
    >
      <Grid
        aria-label='Home header'
        templateAreas={{
          base: `
            "tabs"
            "title"
            "create"
          `,
          md: `"tabs create"`,
        }}
        templateColumns={{ base: '1fr', md: 'auto 16.42rem' }}
        rowGap={{ base: '1.71rem' }}
        templateRows={'auto'}
        mb={{ base: '2.28rem', md: '0' }}
      >
        <GridItem area={'tabs'}>
          <Box width={'auto'}>
            <Tabs index={tabIndex} variant='homeScreen' isFitted={size !== 'desktop'}>
              <TabList>
                {availableCurrencies.map((currency, index) => (
                  <Tab key={index}>
                    <Link to={currency.link}>{currency.title}</Link>
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Box>
        </GridItem>
        <GridItem area={'create'}>
          <Stack alignItems={'center'}>
            <Button
              w='full'
              onClick={() => navigate('/create-deal')}
              maxW={{ base: '20rem', md: 'none' }}
              className='createDealButton'
            >
              {t('buttons.createDeal')}
            </Button>
            <chakra.p
              fontSize={'1rem'}
              fontWeight={500}
              textAlign={'center'}
              maxW={{ base: '20rem', md: 'none' }}
            >
              {t('mainPage.buttonSign')}
            </chakra.p>
          </Stack>
        </GridItem>
        <GridItem area={'title'} display={{ base: 'block', md: 'none' }}>
          <chakra.p fontSize={'1.28rem'} color='brand.black.700'>
            {t('mainPage.tableTitle', { currency: params.currency })}
          </chakra.p>
        </GridItem>
      </Grid>
      <Box>
        <Outlet></Outlet>
      </Box>
    </Grid>
  )
}
