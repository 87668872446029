import React, { FC, useMemo } from 'react'
import { ForgotPassword, MainLayout, SignIn, SignUp } from 'pages'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { RequireAuth } from './RequireAuth'
import { ResetPassword } from 'pages/ResetPassword'
import {
  PersonalInfo,
  SettingsMainPage,
  Notifications,
  Security,
  Payouts,
  FAQ,
  Documents,
} from 'pages/Settings'
import { HomePage } from 'pages/HomePage'
import { DealsTable } from 'pages/HomePage/DealsTable'
import { CreateDeal } from 'pages/CreateDeal'
import { Wallet } from 'pages/Wallet'
import { DealsTransactionsTable } from 'pages/Wallet/DealsTransactionsTable'
import { MyDeals } from 'pages/MyDeals'
import { MyDealsTable } from 'pages/MyDeals/MyDealsTable'
import { PayoutAccounts } from 'pages/Settings/Payouts/PayoutAccounts'
import { WalletTransactionsTable } from 'pages/Wallet/WalletTransactions'
import { TermsAndConditions } from 'pages/Legal/TermsAndConditions'
import { PrivacyPolicy } from 'pages/Legal/PrivacyPolicy'
import Maintenance from 'pages/Maintenance'
import { useSelector } from 'react-redux'
import { RootStore } from 'store'
import { NotAuthorizedDeals } from 'pages/NotAuthorisedDeals'

// take from env

const defaultRoutes = [
  {
    path: '/',
    element: (
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: 'settings',
        children: [
          {
            path: '',
            element: <SettingsMainPage></SettingsMainPage>,
          },
          {
            path: 'personal-info',
            element: <PersonalInfo></PersonalInfo>,
          },
          {
            path: 'notifications',
            element: <Notifications />,
          },
          {
            path: 'security',
            element: <Security />,
          },
          {
            path: 'payouts',
            children: [
              {
                path: '',
                element: <Payouts />,
              },
              {
                path: 'accounts',
                element: <PayoutAccounts></PayoutAccounts>,
              },
            ],
          },
          {
            path: 'faq',
            element: <FAQ />,
          },
          {
            path: 'documents',
            element: <Documents></Documents>,
          },
        ],
      },
      {
        path: 'wallet',
        element: <Wallet />,
        children: [
          {
            path: 'deals-transactions',
            element: <DealsTransactionsTable />,
          },
          {
            path: 'wallet-transactions',
            element: <WalletTransactionsTable />,
          },
          {
            path: '',
            element: <Navigate to='deals-transactions'></Navigate>,
          },
        ],
      },
      {
        path: 'deals',
        element: <MyDeals></MyDeals>,
        children: [
          {
            path: ':dealsStatus',
            element: <MyDealsTable></MyDealsTable>,
          },
        ],
      },

      {
        path: 'create-deal',
        element: <CreateDeal></CreateDeal>,
      },
      {
        path: '/main',
        element: <HomePage />,
        children: [
          {
            path: ':currency',
            element: <DealsTable />,
          },
        ],
      },
      {
        path: '',
        element: <Navigate to='/main' />,
      },
      {
        path: '*',
        element: <Navigate to='/main' />,
      },
    ],
  },
]

export const Router: FC = () => {
  const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true'
  const isLoggedInState = useSelector((state: RootStore) => state.auth.token)
  const isLoggedIn = useMemo(() => {
    const tokenFromStorage = localStorage.getItem('token')
    return !!isLoggedInState || tokenFromStorage
  }, [isLoggedInState])

  const routes = useMemo(() => {
    if (isMaintenance) {
      return [
        {
          path: '/',
          element: <Maintenance />,
        },
        {
          path: '*',
          element: <Navigate to='/' />,
        },
      ]
    }
    if (isLoggedIn) {
      return defaultRoutes
    }
    return [
      {
        path: '/sign-up',
        element: <SignUp />,
      },
      {
        path: '/sign-in',
        element: <SignIn />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: '/reset-password',
        element: <ResetPassword />,
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/main',
        element: <NotAuthorizedDeals />,
        children: [
          {
            path: ':currency',
            element: <DealsTable isAuthorized={false} />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to='/main' />,
      },
    ]
  }, [isLoggedIn, isMaintenance])

  const router = createBrowserRouter(routes)

  return <RouterProvider router={router} />
}
