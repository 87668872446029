import {
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps,
  LayoutProps,
} from '@chakra-ui/react'
import React from 'react'

export const FSGModalWrapper: React.FC<
  ModalProps & { minWidth?: ModalContentProps['minWidth']; height?: LayoutProps['height'] }
> = ({ children, minWidth, ...props }) => {
  return (
    <Modal {...props} isCentered closeOnEsc closeOnOverlayClick={false} scrollBehavior={'inside'}>
      <ModalOverlay></ModalOverlay>
      <ModalContent
        maxW={minWidth || { base: 'full', sm: '37.85rem' }}
        height={props.height}
        overflow='auto'
        sx={{
          overflowX: 'hidden',
        }}
      >
        {children}
      </ModalContent>
    </Modal>
  )
}
