import React, { PropsWithChildren, useRef } from 'react'
import { Provider, useSelector } from 'react-redux'
import { RootStore, store, useTypedDispatch } from 'store'
import { Router } from 'router'
import { Helmet } from 'react-helmet'
import { VerifyModal } from 'components/modals/VerifyModal'
import { closeVerificationModal } from 'store/slices/auth'
import { ScrollHandlersProvider } from 'pages/MainLayout/ScrollContext'
import { AmplitudeProvider } from 'pages/MainLayout/AmplitudeProvider'
import { useDprWithOs } from 'hooks/useDprWithOs'
import { ChakraProvider } from '@chakra-ui/react'
import { ToastContainer } from 'components/FSGToast'
import theme from 'theme'
import { ChatProvider } from 'pages/MainLayout/ChatProvider'

type OnConfirm = (data: any) => void

export const VerificationContext = React.createContext<{
  onConfirm?: OnConfirm
  setOnConfirm?: (handler?: OnConfirm) => void
}>({})

const VerificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const onConfirm = useRef<OnConfirm | undefined>(undefined)

  const otpState = useSelector((state: RootStore) => state.auth.otpState)
  const dispatch = useTypedDispatch()
  return (
    <VerificationContext.Provider
      value={{
        onConfirm: onConfirm.current,
        setOnConfirm: (handler?: OnConfirm) => {
          onConfirm.current = handler
        },
      }}
    >
      {children}
      {otpState.state && (
        <VerifyModal
          isOpen={otpState.isOpen}
          type={otpState.state.otpType}
          onClose={() => dispatch(closeVerificationModal())}
          purpose={otpState.state.purpose}
          userId={otpState.state.userId}
          onConfirm={async (data: any) => {
            onConfirm.current?.(data)
            dispatch(closeVerificationModal())
          }}
        ></VerifyModal>
      )}
    </VerificationContext.Provider>
  )
}

function App() {
  const drp = useDprWithOs()
  return (
    <ChakraProvider theme={theme(drp)}>
      <ChatProvider>
        <ScrollHandlersProvider>
          <div className='App'>
            <Helmet>
              <meta charSet='utf-8' />
              <title>FSGXChange</title>
            </Helmet>
            <Provider store={store}>
              <AmplitudeProvider>
                <VerificationProvider>
                  <Router />
                </VerificationProvider>
              </AmplitudeProvider>
            </Provider>
          </div>
        </ScrollHandlersProvider>
        <ToastContainer></ToastContainer>
      </ChatProvider>
    </ChakraProvider>
  )
}

export default App
