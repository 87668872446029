import { Box, Button, Container, Image } from '@chakra-ui/react'
import React from 'react'
import logo from 'assets/images/logo-no-bg.png'
import { useNavigate } from 'react-router-dom'

export const Header = () => {
  const navigate = useNavigate()

  return (
    <Container
      maxW='container.lg'
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 10,
        mb: '1.5rem',
      }}
      boxShadow={{
        base: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        lg: 'none',
      }}
    >
      <Box
        width={'auto'}
        sx={{
          py: '1.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Image src={logo} alt='logo' width={200} />
        <Button
          onClick={() => {
            navigate('/sign-in')
          }}
          variant='outline'
          colorScheme='brand'
          size='lg'
        >
          Sign In
        </Button>
      </Box>
    </Container>
  )
}
