import React, { useEffect, useMemo } from 'react'
import { Box, Container, Tab, TabList, Tabs, useBreakpointValue } from '@chakra-ui/react'
import { FC } from 'react'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'
import { Header } from './Header'

const availableCurrencies = [
  { title: 'CAD deals', link: 'CAD' },
  { title: 'NGN deals', link: 'NGN' },
]

export const NotAuthorizedDeals: FC = () => {
  const navigate = useNavigate()
  const { currency } = useParams()
  const size = useBreakpointValue({ base: 'mobile', lg: 'desktop' }, { ssr: false })

  const tabIndex = useMemo(() => {
    return availableCurrencies.findIndex((item) => item.link === currency)
  }, [currency])

  useEffect(() => {
    console.log('currency', currency)
    if (!currency) {
      navigate('NGN')
    }
  }, [currency])

  return (
    <Box>
      <Header />
      <Container maxW='container.lg'>
        <Box
          width={'auto'}
          sx={{
            mb: '1.5rem',
          }}
        >
          <Tabs index={tabIndex} variant='homeScreen' isFitted={size !== 'desktop'}>
            <TabList>
              {availableCurrencies.map((currency, index) => (
                <Tab key={index}>
                  <Link to={currency.link}>{currency.title}</Link>
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </Box>
        <Outlet />
      </Container>
    </Box>
  )
}
