import { ChevronRightIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { userAccountIsSetup, userInfoSelector } from 'store/selectors/user'
import { MarketplaceDeal } from 'types'

export function ViewDetails({
  onClick,
  deal,
  isAuthorized,
}: {
  onClick: () => void
  deal: MarketplaceDeal
  isAuthorized: boolean
}) {
  const { t } = useTranslation()
  const userInfo = useSelector(userInfoSelector)
  const isAccountSetup = useSelector(userAccountIsSetup)

  return (
    <Button
      isDisabled={isAuthorized && (userInfo?.id === deal.creatorId || !isAccountSetup)}
      variant='link'
      color={'brand.primary.purple'}
      fontSize='1.14rem'
      fontWeight={800}
      onClick={onClick}
      cursor='pointer'
    >
      {t('tables.mainDeals.viewDetails')} <ChevronRightIcon fontSize={'1.42rem'}></ChevronRightIcon>
    </Button>
  )
}
