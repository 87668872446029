import { InfoIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/react'
import { useDealExchangeRate } from 'hooks'
import React, { FC, useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { BuyDealVerificationResult, MarketplaceDeal, VerificationDeal } from 'types'
import { currencyFormat } from 'utils/currencyFormat'

const billRenderer = (
  text: string,
  value: string,
  description?: Record<string, number | string | undefined>,
  discountValue?: string,
  discount?: number,
) => {
  return (
    <Flex
      position='relative'
      justifyContent={'space-between'}
      color='brand.black.700'
      lineHeight={'120%'}
      width='100%'
    >
      <Box
        position={'absolute'}
        width='100%'
        borderStyle={'dashed'}
        borderWidth='0.07rem'
        opacity={0.5}
        top='50%'
        borderColor={'brand.black.700'}
      ></Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        bg='brand.black.100'
        fontWeight={'600'}
        paddingRight={'0.57rem'}
      >
        {text}
      </Box>
      <Box
        fontSize={'1rem'}
        position={'relative'}
        zIndex={3}
        fontWeight='800'
        bg='brand.black.100'
        paddingLeft={'0.57rem'}
        paddingRight='0.14rem'
        display={'flex'}
        gap='0.28rem'
      >
        {discount ? (
          <Box>
            <Box as={'span'} textDecoration='line-through'>
              {discountValue}
            </Box>
            <Box as={'span'} ml='0.57rem'>
              {value}
            </Box>
          </Box>
        ) : (
          value
        )}

        {description && (
          <Popover>
            <PopoverTrigger>
              <InfoIcon
                fontSize={'1.14rem'}
                color={'brand.secondary.blue'}
                opacity={0.2}
              ></InfoIcon>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Calculation description</PopoverHeader>
              <PopoverBody>
                <Stack spacing='0.57rem'>
                  {Object.entries(description).map(([key, value]) => {
                    return (
                      <Flex justifyContent={'space-between'} key={key}>
                        <Box>
                          <Trans i18nKey={`mainPage.${key}`}></Trans>
                        </Box>
                        <Box>{currencyFormat(+(value || 0))}</Box>
                      </Flex>
                    )
                  })}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Box>
    </Flex>
  )
}

interface Props {
  deal: MarketplaceDeal | VerificationDeal
  amount: number
  verificationResult?: BuyDealVerificationResult
}

export const TransactionDetails: FC<Props> = ({ deal, amount, verificationResult }) => {
  const { t } = useTranslation()
  const convertedAmount = +deal.rate * amount
  const commission = verificationResult?.commissionAmount
  const discount = verificationResult?.discount
  const exchangeRate = useDealExchangeRate(
    'fromCurrencySlug' in deal ? deal.toCurrencySlug : deal.toCurrency.slug,
    'fromCurrencySlug' in deal ? deal.fromCurrencySlug : deal.fromCurrency.slug,
    deal.rate,
  )

  const getTotalAmount = useMemo(() => {
    return convertedAmount + (commission || 0) - (discount || 0)
  }, [convertedAmount, commission, discount])

  if ('fromCurrencySlug' in deal) {
    return (
      <Box bg='brand.black.100' borderRadius={'1.14rem'} p='1.14rem'>
        <Box fontWeight={800} fontSize='1.28rem' color={'brand.black.900'} mb='1.14rem'>
          {t('wallet.transactionDetails')}
        </Box>
        <Stack fontSize={'1rem'} spacing='1.14rem'>
          {billRenderer(t('mainPage.exchangeRate'), exchangeRate)}
          {billRenderer(
            t('mainPage.dealAvailableAmount'),
            `${currencyFormat(+deal.amount - +deal.totalSold)} ${deal.fromCurrencySlug}`,
          )}
          {billRenderer(
            t('mainPage.convertedAmount'),
            `${currencyFormat(convertedAmount)} ${deal.toCurrencySlug}`,
          )}
          {billRenderer(
            t('mainPage.commission'),
            commission !== undefined
              ? `${currencyFormat(commission)} ${deal.toCurrencySlug}`
              : 'N/A',
            undefined,
            `${currencyFormat(discount || 0)} ${deal.toCurrencySlug}`,
            discount,
          )}
        </Stack>
        <Divider my='1.14rem' borderColor={'brand.black.200'} opacity='1'></Divider>
        {billRenderer(
          t('mainPage.totalAmount'),
          commission !== undefined
            ? `${currencyFormat(getTotalAmount)} ${deal.toCurrencySlug}`
            : 'N/A',
          commission ? { commission, discount } : undefined,
        )}
        <Divider my='1.14rem' borderColor={'brand.black.200'} opacity='1'></Divider>
        <Flex
          justifyContent={'space-between'}
          fontWeight={800}
          fontSize='1.28rem'
          color={'brand.black.900'}
        >
          <Box>{t('mainPage.amountWillReceive')}</Box>
          <Box>
            {currencyFormat(amount)} {deal.fromCurrencySlug}
          </Box>
        </Flex>
      </Box>
    )
  } else {
    return (
      <Box bg='brand.black.100' borderRadius={'1.14rem'} p='1.14rem'>
        <Box fontWeight={800} fontSize='1.28rem' color={'brand.black.900'} mb='1.14rem'>
          {t('wallet.transactionDetails')}
        </Box>
        <Stack fontSize={'1rem'} spacing='1.14rem'>
          {billRenderer(t('mainPage.exchangeRate'), exchangeRate)}
          {billRenderer(
            t('mainPage.dealAvailableAmount'),
            `${currencyFormat(+deal.amount - +deal.totalSold)} ${deal.fromCurrency.slug}`,
          )}
          {billRenderer(
            t('mainPage.convertedAmount'),
            `${currencyFormat(convertedAmount)} ${deal.toCurrency.slug}`,
          )}
          {billRenderer(
            t('mainPage.commission'),
            commission !== undefined
              ? `${currencyFormat(commission)} ${deal.toCurrency.slug}`
              : 'N/A',
          )}
          {billRenderer(
            t('mainPage.discount'),
            discount !== undefined ? `${currencyFormat(discount)} ${deal.toCurrency.slug}` : 'N/A',
          )}
        </Stack>
        <Divider my='1.14rem' borderColor={'brand.black.200'} opacity='1'></Divider>
        {billRenderer(
          t('mainPage.totalAmount'),
          commission !== undefined
            ? `${currencyFormat(getTotalAmount)} ${deal.toCurrency.slug}`
            : 'N/A',
          commission !== undefined ? { commission, discount } : undefined,
        )}
        <Divider my='1.14rem' borderColor={'brand.black.200'} opacity='1'></Divider>
        <Flex
          justifyContent={'space-between'}
          fontWeight={800}
          fontSize='1.28rem'
          color={'brand.black.900'}
        >
          <Box>{t('mainPage.amountWillReceive')}</Box>
          <Box>
            {currencyFormat(amount)} {deal.fromCurrency.slug}
          </Box>
        </Flex>
      </Box>
    )
  }
}
